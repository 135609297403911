<!--
 * @Author: your name
 * @Date: 2020-10-26 15:11:46
 * @LastEditTime: 2020-11-24 10:47:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\productionManagement\statisticalAnalysis\materialConsumReport.vue
-->
<template>
    <div class="materialConsumReport">
        <div class="main">
            <section>
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="选择时间">
                        <el-date-picker
                            v-model="pickerDate"
                            type="datetimerange"
                            placeholder="选择时间"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="选择场站">
                        <el-select v-model="formInline.station_id" placeholder="选择场站    ">
                            <el-option
                                v-for="item in stations"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSearch">
                            查询
                        </el-button>
                        <el-button type="primary" class="export-btn" @click="exportTable">
                            <i class="iconfont icondaochu"></i>
                            <span>导出excel</span>
                        </el-button>
                    </el-form-item>
                </el-form>
            </section>
            <div class="table-container">
                <table id="table">
                    <thead>
                        <tr>
                            <th :colspan="tableData.list_ycl.length+6">
                                <!-- {{ TenantName }}中联混凝土有限公司销售日报表 {{ stationName }} -->
                                原材料消耗日报表{{ stationName }}
                            </th>
                        </tr>
                        <tr>
                            <th :colspan="tableData.list_ycl.length+6" class="date-row">
                                {{ date }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <template v-for="item in tableData.list_ycl">
                                <td
                                    :key="item.strength_grade_name"
                                >
                                    {{ item.strength_grade_name }}
                                </td>
                            </template>
                            <td>砂浆</td>
                            <td></td>
                            <td></td>
                            <td>合计</td>
                            <td>年合计</td>
                        </tr>
                        <tr>
                            <td>本日产量</td>
                            <template
                                v-for="item in tableData.list_rcl"
                            >
                                <td :key="`rcl_${item.strength_grade_name}`">
                                    {{ item.cl }}
                                </td>
                            </template>
                            <td>{{ tableData.sj_rcl }}</td>
                            <td></td>
                            <td></td>
                            <td>{{ tableData.hj_rcl }}</td>
                            <td rowspan="2">
                                {{ tableData.ncl }}
                            </td>
                        </tr>
                        <tr>
                            <td>月累计量</td>
                            <template
                                v-for="item in tableData.list_ycl"
                            >
                                <td :key="`ycl_${item.strength_grade_name}`">
                                    {{ item.cl }}
                                </td>
                            </template>
                            <td>{{ tableData.sj_ycl }}</td>
                            <td></td>
                            <td></td>
                            <td>{{ tableData.hj_ycl }}</td>
                        </tr>
                        <tr>
                            <td :rowspan="tableData.materialDatas.length+2">
                                原<br>材<br>料<br>进<br>出<br>记<br>录
                            </td>
                            <td rowspan="2" style="width:150px;">
                                原材料
                            </td>
                            <td rowspan="2">
                                上日数量
                            </td>
                            <td rowspan="2">
                                本日购入数量
                            </td>
                            <td :colspan="tableData.list_ycl.length+1">
                                本日消耗
                            </td>
                            <td rowspan="2">
                                本日库存
                            </td>
                        </tr>
                        <tr>
                            <template v-for="item in tableData.list_ycl">
                                <td
                                    :key="`bottom_${item.strength_grade_name}`"
                                >
                                    {{ item.strength_grade_name }}
                                </td>
                            </template>
                            <td>合计</td>
                        </tr>
                        <tr v-for="item in tableData.materialDatas" :key="item.typecode">
                            <td>{{ item.typename }}</td>
                            <td>{{ item.srkc }}</td>
                            <td>{{ item.brrk }}</td>
                            <template v-for="(v,vIndex) in item.xh">
                                <td :key="`${item.typecode}_${vIndex}`">
                                    {{ v.sl }}
                                </td>
                            </template>
                            <td>{{ item.xhhj }}</td>
                            <td>{{ item.brkc }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import util from '@/utils/util';
export default {
    components: {},
    props: {},
    data() {
        return {
            pickerDate: '',
            // 查询条件
            formInline: {
                start_date: '',
                end_date: '',
                station_id: '',
            },
            // 场站列表
            stations: [],
            // 表格数据
            tableData: {
                list_ycl: [],
                materialDatas: [],
            },
            // 租户名称
            TenantName: '',
            // 表格日期
            date: '',
            // 表格场站
            stationName: '',
        };
    },
    watch: {
        pickerDate(date) {
            if (date) {
                this.formInline.start_date = date[0];
                this.formInline.end_date = date[1];
            } else {
                this.formInline.start_date = '';
                this.formInline.end_date = '';
            }
        },
    },
    computed: {},
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        // 初始化
        init() {
            // 初始化场站
            this.handleStation();
            // 初始化table
            this.hanleMaterialConsum();
            // 租户名称
            this.TenantName = this.$takeTokenParameters('TenantName');
            if (this.TenantName) {
                this.TenantName = this.TenantName.substr(0, 2);
            }
            // 初始化当前日期
            this.formInline.start_date = util.currentTimeFormat('yyyy-MM-dd') + ' 00:00:00';
            const nextDayDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
            this.formInline.end_date = util.timeFormat(nextDayDate, 'yyyy-MM-dd') + ' 00:00:00';
            this.pickerDate = [this.formInline.start_date, this.formInline.end_date];
            this.date = this.formInline.start_date + ' 至 ' + this.formInline.end_date;
        },
        // 搜索
        onSearch() {
            this.date = this.formInline.start_date + ' 至 ' + this.formInline.end_date;
            if (this.formInline.station_id) {
                const arr = this.stations.filter(item => this.formInline.station_id === item.id);
                if (arr.length > 0) {
                    this.stationName = '(' + arr[0].name + ')';
                }
            } else {
                this.stationName = '';
            }
            this.hanleMaterialConsum();
        },
        // 导出table
        exportTable() {
            /* 从表生成工作簿对象 */
            const wb = XLSX.utils.table_to_book(document.querySelector('table'));
            /* 获取二进制字符串作为输出 */
            const wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'array',
            });
            try {
                FileSaver.saveAs(
                // Blob 对象表示一个不可变、原始数据的类文件对象。
                // Blob 表示的不一定是JavaScript原生格式的数据。
                // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                    new Blob([wbout], { type: 'application/octet-stream' }),
                    // 设置导出文件名称
                    this.TenantName + '中联混凝土有限公司销售日报表.xlsx'
                );
            } catch (e) {
                if (typeof console !== 'undefined') {console.log(e, wbout);}
            }
            return wbout;
        },
        // 处理场站数据
        async handleStation() {
            const res = await this.getStation();
            if (res) {
                const total = {
                    id: '',
                    name: '全部',
                };
                this.stations = res;
                this.stations.unshift(total);
            }
        },
        // 处理表格数据
        async hanleMaterialConsum() {
            const res = await this.getMaterialConsum();
            if (res) {
                this.tableData = res;
            }
        },
        // 获取场站
        getStation() {
            const path = '/interfaceApi/report/salesanalysis/station';
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取原材料日报数据
        getMaterialConsum() {
            const path = '/interfaceApi/report/yclxh/yclxhrbb?start_date='
                + this.formInline.start_date + '&end_date=' + this.formInline.end_date + '&station_id=' + this.formInline.station_id;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
.materialConsumReport
    .main
        background-color #fff!important
        height 100%
        padding .2rem
        section
            .el-date-editor
                width auto!important
                height 0.36rem
                padding-right 0;
                /deep/ .el-range__close-icon
                    display flex
                    align-items center
            .el-button
                border: none;
                color: #fff;
                height: 0.36rem;
                font-size: 0.14rem;
                padding: 0 0.2rem;
                border-radius: 2px;
                min-width: 0.36rem;
                line-height: 0.36rem;
                background: #2978ff;
                cursor: pointer;
        .table-container
            height calc(100% - .62rem)
            overflow-y auto
            table
                border-collapse collapse
                border 1px solid #999
                width 100%
                td,th
                    border 1px solid #999
                    width 1rem
                    padding .06rem .1rem
                    text-align center
                    font-size .14rem
                th
                    font-size .16rem
                .date-row
                    text-align right
                    font-size .14rem
                    height .32rem
</style>
